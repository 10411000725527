import { HTML_MEDIA_READY_STATE, getElement } from "./util";

export type Action =
  | "pause"
  | "play"
  | "togglePause"
  | "volumeUp"
  | "volumeDown"
  | "toggleMute"
  | "skip"
  | "remove"
  | "fullscreen";

export function getActionForKey(ev: KeyboardEvent): Action | null {
  if (ev.altKey || ev.ctrlKey || ev.metaKey || ev.shiftKey) {
    return null;
  }

  switch (ev.key) {
    case " ":
    case "Pause":
    case "MediaPlayPause":
      return "togglePause";

    case "AudioVolumeMute":
      return "toggleMute";

    case "ArrowUp":
    case "AudioVolumeUp":
      return "volumeUp";

    case "ArrowDown":
    case "AudioVolumeDown":
      return "volumeDown";

    case "MediaTrackNext":
    case "MediaNextTrack":
    case "MediaSkipForward":
      return "skip";

    case "MediaPause":
      return "pause";

    case "MediaPlay":
      return "play";

    case "f":
      return "fullscreen";

    default:
      return null;
  }
}

export function initIndexPage() {
  const infoFrame = getElement("info-frame", HTMLIFrameElement);
  const audio = getElement("stream", HTMLAudioElement);
  const skipButton = getElement("skip", HTMLButtonElement);
  const removeButton = getElement("remove", HTMLButtonElement);
  let restartTimeout: number | undefined = undefined;

  function restartStream() {
    restartTimeout = setInterval(() => {
      const src = new URL(audio!.currentSrc);
      src.searchParams.set("cache_buster", Date.now().toString());

      audio.src = src.toString();
      audio.play();
    }, 5000);
  }

  audio.addEventListener("ended", () => {
    if (restartTimeout === undefined) {
      restartStream();
    }
  });

  audio.addEventListener("error", () => {
    if (restartTimeout !== undefined) {
      clearTimeout(restartTimeout);
      restartStream();
    }
  });

  interface PlayerState {
    volume?: number;
    muted?: boolean;
    paused?: boolean;
  }

  let paused: boolean;
  function getPlayerState(): PlayerState {
    return {
      volume: audio.volume,
      muted: audio.muted,
      paused,
    };
  }

  function pause() {
    audio.pause();
    audio.autoplay = false;
    audio.preload = "none";
    paused = true;

    const src = new URL(audio.currentSrc !== "" ? audio.currentSrc : audio.src);
    src.searchParams.set("cache_buster", Date.now().toString());

    audio.src = src.toString();
  }

  const stateStr = localStorage.getItem("playerState");
  if (stateStr !== null && stateStr !== "") {
    const state: PlayerState = JSON.parse(stateStr);

    if (typeof state.volume === "number" && isFinite(state.volume)) {
      audio.volume = state.volume;
    }

    if (typeof state.muted === "boolean") {
      audio.muted = state.muted;
    }

    if (typeof state.paused === "boolean" && state.paused) {
      pause();
    }
  }

  audio.addEventListener("volumechange", () => {
    localStorage.setItem("playerState", JSON.stringify(getPlayerState()));
  });

  audio.addEventListener("playing", () => {
    paused = false;
    localStorage.setItem("playerState", JSON.stringify(getPlayerState()));

    if (restartTimeout !== undefined) {
      clearTimeout(restartTimeout);
      restartTimeout = undefined;

      if (infoFrame !== undefined) {
        infoFrame.src = infoFrame.src;
      }
    }
  });

  audio.addEventListener("pause", () => {
    if (audio.readyState > HTML_MEDIA_READY_STATE.HAVE_CURRENT_DATA) {
      // paused by user instead of stream ending / error
      pause();
      localStorage.setItem("playerState", JSON.stringify(getPlayerState()));
    }
  });

  skipButton.addEventListener("click", (ev) => {
    ev.preventDefault();
    infoFrame.contentWindow?.dispatchEvent(
      new CustomEvent<Action>("action", { detail: "skip" }),
    );
  });

  removeButton.addEventListener("click", (ev) => {
    ev.preventDefault();
    infoFrame.contentWindow?.dispatchEvent(
      new CustomEvent<Action>("action", { detail: "remove" }),
    );
  });

  const titleBase = document.title;
  addEventListener("trackInfo", (ev) => {
    if (ev.detail !== undefined) {
      if (ev.detail.metadata.artist !== null) {
        document.title = `${
          ev.detail.metadata.title || ev.detail.metadata.filename
        } - ${ev.detail.metadata.artist} - ${titleBase}`;
      } else {
        document.title = `${
          ev.detail.metadata.title || ev.detail.metadata.filename
        } - ${titleBase}`;
      }
    } else {
      document.title = titleBase;
    }
  });

  addEventListener("keydown", (ev) => {
    const action = getActionForKey(ev);
    if (action !== null) {
      ev.preventDefault();

      const actionEvent = new CustomEvent<Action>("action", { detail: action });
      dispatchEvent(actionEvent);
      infoFrame.contentWindow?.dispatchEvent(actionEvent);
    }
  });

  addEventListener("action", (ev) => {
    switch (ev.detail) {
      case "pause":
        audio.pause();
        break;

      case "play":
        audio.play();
        break;

      case "togglePause":
        if (audio.paused) {
          audio.play();
        } else {
          audio.pause();
        }
        break;

      case "volumeDown":
        audio.volume = Math.max(0, audio.volume - 0.05);
        break;

      case "volumeUp":
        audio.volume = Math.min(1, audio.volume + 0.05);
        break;

      case "toggleMute":
        audio.muted = !audio.muted;
        break;
    }
  });
}
