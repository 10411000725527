import { getActionForKey } from "./indexPage";
import { formatDuration, getElement } from "./util";

interface Timing {
  sample_rate: number;
  current_frame: number;
  total_frames: number | null;
}

interface PlaylistIndex {
  index: number;
  playlist: number;
}

interface Metadata {
  title: string | null;
  artist: string | null;
  album: string | null;
  filename: string | null;
}

interface TimingInfo {
  timing: Timing;
}

export interface TrackInfo extends TimingInfo {
  id: PlaylistIndex;
  metadata: Metadata;
}

function isTrackInfo(info: TimingInfo): info is TrackInfo {
  return typeof (info as TrackInfo).id === "object";
}

export function initInfoPage(info?: TrackInfo) {
  const url = new URL(location.href);

  if (!url.searchParams.has("js")) {
    url.searchParams.set("js", "");
    location.replace(url);
  }

  const wsUrl = new URL("api/info", url);

  const token = url.searchParams.get("key") ?? undefined;
  if (token !== undefined) {
    wsUrl.searchParams.set("key", token);
  }

  switch (url.protocol) {
    case "http:":
      wsUrl.protocol = "ws";
      break;

    case "https:":
      wsUrl.protocol = "wss";
      break;

    default:
      throw new Error("Unknown protocol");
  }

  let ws: WebSocket;
  let info_time = Date.now();

  function connectInfo() {
    ws = new WebSocket(wsUrl);
    ws.addEventListener("close", () => setTimeout(connectInfo, 5000));

    ws.addEventListener("message", (ev: MessageEvent<string>) => {
      info_time = Date.now();

      if (!ev.data) {
        info = undefined;
        renderTrackInfo(url, info, token);
      } else {
        const data = JSON.parse(ev.data);

        if (!data) {
          info = undefined;
          renderTrackInfo(url, info, token);
        } else if (isTrackInfo(data)) {
          info = data;
          renderTrackInfo(url, info, token);
        } else if (info !== undefined) {
          info.timing = data.timing;
        }
      }

      if (info !== undefined) {
        setProgressAnimation(info.timing);
        renderTiming(info.timing, 0);
      }
    });
  }

  connectInfo();

  addEventListener("action", (ev) => {
    switch (ev.detail) {
      case "skip":
        fetch("api/skip", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(info?.id),
        });
        break;

      case "remove":
        fetch("api/remove", {
          method: "post",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(info?.id),
        });
        break;

      case "fullscreen":
        if (
          document.fullscreenElement === null ||
          document.fullscreenElement === undefined
        ) {
          document.body.requestFullscreen();
        } else {
          document.exitFullscreen();
        }
        break;
    }
  });

  setInterval(() => {
    if (info !== undefined) {
      renderTiming(info.timing, (Date.now() - info_time) / 1000);
    }
  }, 1000);

  addEventListener("keydown", (ev) => {
    const action = getActionForKey(ev);
    if (action !== null) {
      ev.preventDefault();

      const actionEvent = new CustomEvent("action", { detail: action });
      dispatchEvent(actionEvent);
      parent.dispatchEvent(actionEvent);
    }
  });
}

function setProgressAnimation(timing: Timing) {
  if (timing.total_frames !== null) {
    const progressBar = getElement("progress-bar");

    progressBar.style.setProperty(
      "--progress",
      `${(timing.current_frame / timing.total_frames) * 100}%`,
    );

    progressBar.style.setProperty(
      "--remaining-time",
      `${(timing.total_frames - timing.current_frame) / timing.sample_rate}s`,
    );

    if (progressBar.style.animationName == "progress-bar") {
      progressBar.style.animationName = "progress-bar2";
    } else {
      progressBar.style.animationName = "progress-bar";
    }
  }
}

function renderTrackInfo(
  baseUrl: URL,
  info: TrackInfo | undefined,
  token: string | undefined,
) {
  const trackInfo = getElement("track-info");
  const cover = getElement("cover", HTMLImageElement);

  if (info === undefined) {
    trackInfo.style.display = "none";
    cover.style.display = "none";
  } else {
    trackInfo.style.display = "";
    getElement("artist").innerText = info.metadata.artist ?? "";
    getElement("album").innerText = info.metadata.album ?? "";
    getElement("title").innerText =
      info.metadata.title ?? info.metadata.filename ?? "";

    const coverUrl = new URL("cover", baseUrl);
    coverUrl.searchParams.set("playlist", info.id.playlist.toString());
    coverUrl.searchParams.set("index", info.id.index.toString());

    if (token !== undefined) {
      coverUrl.searchParams.set("key", token);
    }

    cover.src = coverUrl.toString();
    cover.style.display = "";
  }

  parent.dispatchEvent(new CustomEvent("trackInfo", { detail: info }));
}

function renderTiming(timing: Timing, offset_seconds: number) {
  let elapsed: string | null = null;
  const timingElement = getElement("timing");

  if (timing.total_frames !== null) {
    if (
      timing.current_frame / timing.sample_rate + offset_seconds >
      timing.total_frames / timing.sample_rate
    ) {
      getElement("remaining").innerText = "??:??";
      elapsed = "??:??";
    } else {
      getElement("remaining").innerText = formatDuration(
        -(timing.total_frames - timing.current_frame) / timing.sample_rate +
          offset_seconds,
      );
    }

    const length = formatDuration(timing.total_frames / timing.sample_rate);
    getElement("length-inner").innerText = length;
    getElement("length-outer").innerText = length;

    timingElement.classList.remove("no-progress");
  } else {
    timingElement.classList.add("no-progress");
  }

  getElement("elapsed").innerText =
    elapsed ??
    formatDuration(timing.current_frame / timing.sample_rate + offset_seconds);
}
