export const enum HTML_MEDIA_READY_STATE {
  HAVE_NOTHING = 0,
  HAVE_METADATA = 1,
  HAVE_CURRENT_DATA = 2,
  HAVE_FUTURE_DATA = 3,
  HAVE_ENOUGH_DATA = 4,
}

export function getElement(id: string): HTMLElement;

export function getElement<T extends HTMLElement>(
  id: string,
  ty: new (...args: unknown[]) => T,
): T;

export function getElement<T extends HTMLElement>(
  id: string,
  ty?: new (...args: unknown[]) => T,
): HTMLElement {
  const element = document.getElementById(id);

  if (ty === undefined) {
    if (element === null) {
      throw new Error("Element not found");
    }

    return element;
  } else if (element instanceof ty) {
    return element;
  } else {
    throw new Error("Element not found");
  }
}

export function formatDuration(seconds: number) {
  const prefix = seconds < 0 ? "-" : "";
  seconds = Math.abs(seconds) | 0;

  const hours = (seconds / (60 * 60)) | 0;
  seconds %= 60 * 60;

  const minutes = (seconds / 60) | 0;
  seconds %= 60;

  if (hours > 0) {
    return `${prefix}${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  } else {
    return `${prefix}${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }
}
